body {
    background-color: #9399c3;
}

.center {
    text-align: center;
}

.legal-link {
    margin: 20px;
    font-size: large;
    color: #000;
    text-decoration: underline;
}