p {
    text-align: left;
}

h1 {
    text-align: center;
}

.black-link {
    color: #000;
}

.other-legal-link {
    color: #000;
    float: right;
}

.other-legal-footer-link {
    text-align: center;
}